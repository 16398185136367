import React from 'react'
import './Header.css'
import coming from '../../../assets/TiffinServices/tiffin-ComingSoon.png'
import emoji from "../../../assets/TiffinServices/tiffin-emoji.png";
import tiffincontent from "../../../assets/TiffinServices/tiffin-headerText.png";
import chef from "../../../assets/TiffinServices/tiffin-headerImg.png";

const Header = () => {
  return (
    <>
      <div className="coming-soonBanner">
        <img src={coming} alt="" />
        <h3>Our services are not launched yet. We are coming soon.....</h3>
        <img src={emoji} alt="" />
      </div>

      <div className="tiffin-header-content">
        <div className='header-sec1'>
          <img src={tiffincontent} alt="" />
          <p>
            Fall For the taste of home with our Tiffin service. Made with fresh,
            seasonal ingredients and cooked with love, our food is sure to bring
            a smile to your face.
          </p>
        </div>

        <div>
            <img src={chef} alt="" />
        </div>
      </div>
    </>
  );
}

export default Header
