import React from 'react'
import './Header.css'
import banner from '../../../assets/welcomepgBanner.png'
import location from '../../../assets/Location.png'
const Header = () => {
  return (
    <div className="banner">
      <div className="header">
        <div className="header-contents">
          <h2>Treat your cravings with delicious foods</h2>
          <div className="side">
            <input type="text" placeholder="Enter Pincode" />
            {/* <img src={location} alt="" /> */}
            <button>Check Availability</button>
          </div>
        </div>
      </div>
      {/* <img className="banner_img " src={banner} alt="" /> */}
    </div>
  );
}

export default Header
