import React from 'react'
import './FAQs.css'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useState } from 'react';

const FAQs = () => {
  // State to track which question's collapse-content is visible
  const [visibleIndex, setVisibleIndex] = useState(null);

  // State to track which service is selected
  const [selectedService, setSelectedService] = useState("tiffin");

  // Function to toggle visibility of a specific question
  const toggleVisibility = (index) => {
    setVisibleIndex(visibleIndex === index ? null : index);
  };

  const tiffin = [
    {
      question: "Q1. What is the delivery schedule for tiffin service meals?",
      answer:
        "We deliver tiffin service meals on a daily basis. Meals will be delivered after 6hrs of booking. Our delivery schedule ensures that you receive freshly prepared, home-cooked meals every day, making it convenient for your daily routine.",
    },
    {
      question: "Q2. Can I change or cancel my tiffin service subscription?",
      answer:
        "Yes, you can change or cancel your subscription anytime by contacting our support team.",
    },
    {
      question:
        "Q3. Are there any delivery fees or minimum order requirements for tiffin service?",
      answer:
        "We strive to keep our services convenient and affordable. There are no additional delivery fees, and we do not impose minimum order requirements for our tiffin service. However, you have to subscribe to one month minimum in order to receive a 1-time free meal.",
    },
    {
      question: "Q4. What cuisine do you offer in your tiffin service?",
      answer:
        "Our tiffin service embraces culinary diversity. We offer a wide range of cuisines to cater to various preferences. Our menu is designed to provide a delightful dining experience every day.",
    },
    {
      question:
        "Q5. How do I customize my tiffin service menu or request specific dishes?",
      answer:
        "We understand that individual preferences vary. To customize your tiffin service menu or request specific dishes, simply reach out to our customer support team. We offer the flexibility for you to enjoy meals tailored to your taste buds, ensuring a personalized and enjoyable dining experience.",
    },
  ];

  const chef = [
    {
      question:
        "Q1. How do Irequest specific cuisine or menu for my chef service event? ",
      answer:
        "Customizing your culinary experience is part of the charm of our chef service. After booking, you can discuss your preferences, dietary requirements, and any specific cuisine or menu choices directly with the chef. Our goal is to tailor the menu to your liking and create a unique dining experience for your event.",
    },
    {
      question:
        "Q2. What is the cancellation policy for chef service reservations?",
      answer:
        "We understand that plans can change. For chef service reservations, we have a flexible cancellation policy. Please refer to our terms and conditions or contact our customer support for specific details regarding cancellations and any applicable charges.",
    },
    {
      question:
        "Q3. Do you provide the necessary kitchen equipment or ingredients for the chef service?",
      answer:
        "Yes, we strive to make your chef service experience hassle-free. Hence our chefs come prepared with the necessary kitchen equipment to execute the chosen menu. (It is Optional but you can choose weather you want chef to come with ingredients or you will arrange all items by self. Charges will be count accordingly) However, it's recommended to check with the chef during the planning phase to ensure any specific requirements are met.",
    },
    {
      question:
        "Q4. Do you offer wine pairing or beverage services in conjunction with the chef service?",
      answer:
        "Absolutely! To complement your dining experience, many of our chefs offer wine pairing or beverage services. (you can also hire bartenders to complete all your drinking service needs) You can discuss your preferences with the chef during the planning process, and they can recommend and provide a selection of beverages to enhance your culinary journey.",
    },
  ];

  const laundry = [
    {
      question:
        "Q1. Do you offer Dry Cleaning as part of your laundry service?",
      answer:
        "Yes, our laundry service includes dry cleaning options. We provide a comprehensive range of services, including dry cleaning, wash and fold, wash and iron, and shoe cleaning, to cater to your diverse laundry needs.",
    },
    {
      question:
        "Q2. Are there any restrictions on the types of clothing or fabric you can launder?",
      answer:
        "Our laundry service is designed to accommodate a variety of clothing and fabric types. However, it's essential to check the care labels on your garments. Certain delicate or specialty items may require special attention, so we recommend discussing any specific requirements with our laundry professionals.",
    },
    {
      question:
        "Q3. What is your policy for lost or damaged items during laundry service?",
      answer:
        "While we take utmost care with your garments, unforeseen circumstances may arise. In the rare event of lost or damaged items during our laundry service, we have a transparent policy in place. Please refer to our terms and conditions for details on reimbursement or resolution in such cases.",
    },
    {
      question:
        "Q4. Is laundry service available on weekends or for one-time cleaning needs?",
      answer:
        "Yes, our laundry service is available on weekends to cater to your convenience. Additionally, we understand that one-time cleaning needs may arise. You can schedule a one-time pickup and delivery as per your requirements.",
    },
    {
      question: "Q5. How do I schedule a laundry pickup and delivery?",
      answer:
        "Scheduling a laundry pickup and delivery is easy! Simply log in to your account on our website or app, select the desired services, choose a convenient pickup time, and our rider will arrive at your doorstep. You can contact our customer support for scheduling or any specific requests.",
    },
  ];

  const homemaker = [
    {
      question:
        "Q1. What tasks are typically included in your home makers service?",
      answer:
        "Great question! Our home makers service encompasses a range of tasks, including cleaning, bed-making, running errands, and various household chores. We aim to cover everything you need for a well-maintained and comfortable home.",
    },
    {
      question: "Q2. How do you select and vet your home makers?",
      answer:
        "We understand the importance of trust. Our home makers undergo a rigorous selection process, including thorough background checks. We prioritize reliability and professionalism to ensure you feel secure with the individuals we send to assist you.",
    },
    {
      question:
        "Q3. Can I customize the tasks the home maker performs based on my specific needs?",
      answer:
        "Absolutely! We believe in flexibility. You can customize the tasks based on your specific requirements. Just let us know what you need, and we'll ensure our home makers cater to your unique preferences.",
    },
    {
      question:
        "Q4. What happens if the assigned home maker is unable to come on a scheduled day?",
      answer:
        "We understand that life can be unpredictable. In the rare event that the assigned home maker is unavailable, we have backup procedures in place. Rest assured, we'll ensure continuity in our services and communicate any changes promptly.",
    },
    {
      question:
        "Q5. Is the home makers service available on weekends, and can I request specific time slots?",
      answer:
        "Absolutely! We understand the need for flexibility. Our home makers service is available on weekends, and you can indeed request specific time slots that best suit your schedule. Just let us know your preferences, and we'll do our best to accommodate.",
    },
  ];

  const payment = [
    {
      question: "Q1. What happens if the service provider cancels my booking?",
      answer:
        "In the rare event that the service provider cancels your booking, we will notify you as soon as possible and work to reschedule the appointment at your convenience. If rescheduling is not possible, we will provide a full refund in accordance with our refund policy.",
    },
    {
      question: "Q2. How do I book a service with PrepEat?",
      answer:
        "To book a service with PrepEat, simply visit our website or mobile app, select the service you need, choose your desired options, and proceed to checkout. You can also contact our customer support team for assistance with booking.",
    },
    {
      question: "Q3. When will I be charged for my booking?",
      answer:
        "Your payment will be processed at the time of booking confirmation. For certain services, such as chef bookings, a pre-authorization may be placed on your payment method at the time of booking, with the actual charge processed after the service is completed.",
    },
    {
      question: "Q4. Can I modify or cancel my booking?",
      answer:
        "Yes, you can modify or cancel your booking in accordance with our cancellation policy. Please refer to the specific terms and conditions for the service you booked for details on modification and cancellation fees, if any.",
    },
    {
      question: "Q5. How do I request a refund for a canceled booking?",
      answer:
        "If you need to cancel your booking and request a refund, please contact our customer support team at care@prepeat.in. We will review your request in accordance with our refund policy and process the refund to the original payment method used for the transaction.",
    },
    {
      question: "Q6. Do I need to pay in advance for my booking?",
      answer:
        "Yes, we require payment in advance to confirm your booking. This helps us ensure the availability of our services and streamline the booking process for our customers.",
    },
  ];

  // Map selected service to the appropriate questions array
  const questions = {
    tiffin,
    chef,
    laundry,
    homemaker,
    payment,
  }[selectedService];

  const getActiveClass = (service) => {
    return selectedService === service ? "active" : "";
  };

  return (
    <div className="faq">
      <h2 className="faq-heading">FAQs</h2>
      <div className="faq-services">

        <h2 className={getActiveClass("tiffin")} onClick={() => setSelectedService("tiffin")}>Tiffin Service</h2>

        <h2 className={getActiveClass("chef")} onClick={() => setSelectedService('chef')}>Chef Service</h2>

        <h2 className={getActiveClass("laundry")} onClick={() => setSelectedService('laundry')}>Laundry Service</h2>

        <h2 className={getActiveClass("homemaker")} onClick={() => setSelectedService('homemaker')}>Homemaker Service</h2>

        <h2 className={getActiveClass("payment")} onClick={() => setSelectedService('payment')}>Payment & Booking</h2>

      </div>

      <div>
        <div className="questions">
          {questions.map((item, index) => (
            <div className="question" key={index}>
              <div className="collapse-title">
                {item.question}
                {visibleIndex === index ? (
                  <IoIosArrowUp
                    className="arrow-sign"
                    onClick={() => toggleVisibility(index)}
                  />
                ) : (
                  <IoIosArrowDown
                    className="arrow-sign"
                    onClick={() => toggleVisibility(index)}
                  />
                )}
              </div>
              <div
                className={
                  visibleIndex === index ? "collapse-content" : "content-none"
                }
              >
                <p>
                  <span>Answer:</span> {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQs
