import React from "react";
import "./HireChef.css";
import tiffin from '../../../assets/TiffinServices/tiffin-subscribe.png'

const HireChef = () => {
  return (
    <>
      <div className="hireChef">
        <img
          src={require("../../../assets/TiffinServices/tiffin-chef.png")}
          alt="Tiffin Chef"
          className="hireChefImage"
        />
        <div className="hireChefContent">
          <div>
            <h2 className="contTitle">Want To Hire A Personal Chef ?</h2>
            <p>
              Experience the epitome of culinary delight with PrepEat's 'Hire a
              Personal Chef' service. Elevate your dining experience as our
              talented chefs bring their expertise directly to your kitchen,
              curating exquisite meals tailored to your taste. Embrace the
              luxury of personalized gourmet dining without leaving the comfort
              of your home.
            </p>

            <div>
              <button>Book Now</button>
            </div>
          </div>
        </div>
      </div>

      <div className="subSec">
        <div className="subscribe">
          <div>
            <div>
              <h2>Subscribe</h2>
            </div>

            <div>
              <h5>
                On Daily tiffin Starting from
                <span>₹ 200</span>
              </h5>
            </div>
          </div>

          <div>
            <img src={tiffin} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HireChef;
