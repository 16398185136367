import React from 'react'
import './Discount.css'
import { LiaRupeeSignSolid } from "react-icons/lia";
import clock from '../../../assets/clock.png'

const Discount = () => {
  return (
    <>
      <div className="offers">
        <h2>40% OFF</h2>
        <div>
          <h5>
            <span>On Your first tiffin subscription UPTO</span>
            <span className="dis">
              <LiaRupeeSignSolid color="rgb(255 61 0)" />
              200
            </span>
          </h5>
        </div>
      </div>

      <div className="timings">
        <div className="times breakfast">
          <img src={clock} alt="" />
          <h4>Breakfast</h4>
          <h4>7am-9am</h4>
        </div>

        <div className="times lunch">
          <img src={clock} alt="" />
          <h4>Lunch</h4>
          <h4>12pm-2pm</h4>
        </div>

        <div className="times dinner">
          <img src={clock} alt="" />
          <h4>Dinner</h4>
          <h4>8pm-10pm</h4>
        </div>
      </div>
    </>
  );
}

export default Discount
