import React from 'react'
import './WhatWeDo.css'
import girl from '../../../assets/TiffinServices/tiffin-girl.png'

const WhatWeDo = () => {
  return (
    <div>
      <div>
        <div className="ourWork">
          <div>
            <img src={girl} alt="" />
          </div>

          <div className='work-content'>
            <div>
              <h2>What we Do...</h2>
              <p>
                Creating a cooking strategy for a month can help you save time
                and ensure that you have delicious and nutritious meals
                throughout the month.
              </p>
            </div>

            <div className='strategy'>
              <p>Strategy for one month by ricoz</p>
              <ul>
                <li>Plan your meals</li>
                <li>Choose recipes</li>
                <li>Batch cooking</li>
                <li>Use versatile ingredients</li>
                <li>Freezer organization</li>
                <li>Plan leftovers</li>
                <li>Plan for quick and easy meals</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo
