import React from 'react'
import Navbar from './components/Navbar/Navbar'
import Home from './Pages/Home/Home'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './components/Footer/Footer/Footer';
import Tiffin from './Pages/TiffinService/Tiffin';
import FAQs from './components/FAQs/FAQs'

const App = () => {
  return (
    <>
      <div className="app">
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route excat path="/tiffin" element={<Tiffin/>}></Route>
          </Routes>
          <FAQs/>
          <Footer/>
        </Router>
      </div>
    </>
  );
}

export default App
