import React from "react";
import "./Contact_Meal.css";
import { IoCallOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";

const mealOptions = ["Vegetarian", "Non-Vegetarian"];

const locationOptions = [
  "Delhi",
  "Kolkata",
  "Pune",
  "Faridabad",
  "Gurugrama",
  "Nuh",
  "Rohtak",
  "Sonepat",
  "Noida",
  "Rewari",
  "Jhajjhar",
  "Panipat",
  "Palwal",
  "Bhiwani",
  "Charkhi Dadri",
  "Mahendragarhi",
  "Jind",
  "Karnal",
  "Meerut",
  "Ghaziabad",
  "Gautam Budh Nagar",
  "Bulandshahr",
  "Baghpat",
  "Hapur",
];

const Contact_Meal = () => {
  return (
    <>
      <div>
        <div className="contact-meal">
          <div className="time-loc">
            <select name="" id="" className="mealType">
              <option disabled>Select Meal Type</option>
              {mealOptions.map((meal, index) => (
                <option key={index} value={meal}>
                  {meal}
                </option>
              ))}
            </select>

            <select name="" id="" className="loc">
              <option disabled>Select Location</option>
              {locationOptions.map((location, index) => (
                <option key={index} value={location}>
                  {location}
                </option>
              ))}
            </select>
          </div>

          <div className="contact">
            <div>
              <button className="cont btn1">
                <IoCallOutline size={25} />
                <span>Contact Us</span>
              </button>
            </div>

            <div>
              <button className="cont btn2">
                <FaWhatsapp size={25} />
                <span>Whatsapp</span>
              </button>
            </div>
          </div>
        </div>

        <div className="meal">
          <div className="mealTime-sec">
            <div className="mealtime">
              <h2>3 Time Meal(Breakfast+Lunch+Dinner)</h2>
            </div>

            <div className="notAvailable">
              <h2>Not available!</h2>
            </div>
          </div>

          <div className="mealTime-sec">
            <div className="mealtime">
              <h2>
                2 Time Meal(Breakfast+Lunch/ Breakfast+Dinner/ Lunch+Dinner)
              </h2>
            </div>

            <div className="notAvailable">
              <h2>Not available!</h2>
            </div>
          </div>

          <div className="mealTime-sec">
            <div className="mealtime">
              <h2>1 Time Meal(Lunch/Dinner)</h2>
            </div>

            <div className="notAvailable">
              <h2>Not available!</h2>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Contact_Meal;
