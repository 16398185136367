import React from 'react'
import './Tiffin.css'
import Header from '../../Sections/TiffinServices/Header/Header'
import BookTiffin from '../../Sections/TiffinServices/BookTiffin/BookTiffin'
import Discount from '../../Sections/TiffinServices/Discount/Discount'
import Contact_Meal from '../../Sections/TiffinServices/Contact_Meal/Contact_Meal'
import WhatWeDo from '../../Sections/TiffinServices/WhatWeDo/WhatWeDo'
import HireChef from '../../Sections/TiffinServices/HireCHef/HireChef'
const Tiffin = () => {
  return (
    <div className='tiffin'>
      <Header/>
      <BookTiffin/>
      <Discount/>
      <Contact_Meal/>
      <WhatWeDo/>
      <HireChef/>
    </div>
  )
}

export default Tiffin
