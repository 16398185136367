import React from 'react'
import './Welcome.css'
// import assets from '../../../assets'
import img1 from '../../../assets/welcomepg1.png'
import img2 from '../../../assets/welcomepg2.png'
import img3 from '../../../assets/welcoepg3.png'

const Welcome = () => {
  return (
    <div className="welcomePage">
      <h1>
        <span className="span1">Welcome to </span>
        <span className="prep">Prep</span>
        <span className="eat">Eat</span>
      </h1>
      <div className="images">
        <img className="welimg" src={img3} alt="" />
        <img className="welimg" src={img1} alt="" />
        <img className="welimg" src={img2} alt="" />
      </div>
    </div>
  );
}

export default Welcome
