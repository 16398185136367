import React from 'react'
import './BookTiffin.css'
import breakfast from '../../../assets/TiffinServices/tiffin-egg.png'

const BookTiffin = () => {
  return (
    <div className="bookTiffin">
      <h1>You Can Book Tiffin for</h1>
      <div className="availableOptions">
        <div className="card tiffinTime">
          <div className="bookTiffin-body ">
            <img src={breakfast} alt="" />
            <h4 className="card-title">One Day</h4>
            <p className="card-text">
              Enjoy a single day of delicious home-cooked meals delivered to
              your doorstep. Order today for a delightful culinary experience
              with our fresh and flavorful tiffin service.
            </p>
          </div>
        </div>

        <div className="card tiffinTime">
          <div className="bookTiffin-body">
            <img src={breakfast} alt="" />
            <h4 className="card-title">One Week</h4>
            <p className="card-text">
              Simplify your week with our Weekly Tiffin Plan. Receive a variety
              of mouthwatering meals every day, carefully curated to keep your
              taste buds excited.
            </p>
          </div>
        </div>

        <div className="card tiffinTime">
          <div className="bookTiffin-body">
            <img src={breakfast} alt="" />
            <h4 className="card-title">One Month</h4>
            <p className="card-text">
              Experience a month-long culinary journey with our Tiffin
              Subscription. Subscribe now for the ultimate convenience and a
              continuous feast of delectable dishes.
            </p>
          </div>
        </div>

        <div className="card tiffinTime">
          <div className="bookTiffin-body">
            <img src={breakfast} alt="" />
            <h4 className="card-title">Custom days</h4>
            <p className="card-text">
              Tailor your tiffin experience with our Custom Days option. Select
              the specific days you want delicious meals Order now for
              flexibility and flavor.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookTiffin
