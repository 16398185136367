import React from 'react'
import './Home.css'
import Header from '../../Sections/HomePageSections/Header/Header';
import Welcome from '../../Sections/HomePageSections/WelcomePage/Welcome';
import ProductFeatures from '../../Sections/HomePageSections/ProductFeatures/ProductFeatures';
import TryApp from '../../Sections/HomePageSections/TryApp/TryApp';
import Services from '../../Sections/HomePageSections/Services/Services';
import FooterBanner from '../../components/Footer/FooterBanner/FooterBanner';
const Home = () => {
  return (
    <div>
      <Header/>
      <Welcome/>
      <ProductFeatures/>
      <TryApp/>
      <Services/>
      <FooterBanner/>
    </div>
  );
}

export default Home
