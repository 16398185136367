import React from 'react'
import './FooterBanner.css'
import footbanner from '../../../assets/footerBanner.jpeg'
const FooterBanner = () => {
  return (
    <div className="botBanner">
      <div className="bot">
        <div className="bot-contents">
            <h2>Ready to Satisfy Your Cravings?</h2>
            <button>Order Now</button>
        </div>
      </div>
    </div>
  );
}

export default FooterBanner
