import React from 'react'
import './TryApp.css'
import phone from '../../../assets/Phone.png'
import burger from '../../../assets/Burger.png'
import playstore from '../../../assets/Playstore.png'
import A from '../../../assets/A.png'

const TryApp = () => {
  return (
    <div>
      <div className="tryApp">
        <img src={phone} alt="" />
        <div className="content">
          <h4>Try our app now</h4>
          <p>
            Discover the utmost convenience in food ordering. Explore
            irresistible discounts and step into a realm of delightful culinary
            delights.
          </p>
          <div>
            <div>
              <h3>AVAILABLE ON</h3>
              <div className="buttons">
                <div className="appbtn">
                  <img className="btnimg" src={playstore} alt="" />
                  <span>Playstore</span>
                </div>

                <div className="appbtn">
                  <img className="btnimg" src={A} alt="" />
                  <span>Appstore</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="burger" src={burger} alt="" />
      </div>
    </div>
  );
}

export default TryApp
